// import swRuntime from 'serviceworker-webpack-plugin/lib/runtime';

import '../assets/images/favicon.png';
import '../assets/images/appicon.png';
import '../assets/images/logo-gb--melon.svg';
import '../assets/images/logo-gb--white.svg';

import './polyfills';

import { dummy } from './components/dummy';
// import loadChunks from './utils/load-chunks';

dummy();

/**
 * Activate to load chunks
 */
// loadChunks();

if ('serviceWorker' in navigator) {
    /**
     * Activate to register the service worker
     */
    // swRuntime.register();
}
